.About {
  height: 90vh;
  width: 54%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  align-items: center;
  /* margin-top: 200px; */
}


.About p {
  text-align: justify;
  padding: 0 10px;
}


@media (max-width: 1500px) {
  .About {
    width: 98%;
  }

  @media (max-width: 800px) {
    .About {
      width: 100%;
      height: 80vh;
    }

    .About h2 {

      font-size: 1.2rem;
          }
    .About p {

font-size: 0.8rem;
    }
    
  }
}