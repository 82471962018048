.HomePage {
  background-color: rgba(0, 0, 0, 0.914);
  height: 400vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  margin: 0 auto;
}

.HomePage .HomePage-div {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.HomePage .HomePage-div .headers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
}

.HomePage .HomePage-div .logos-img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  border-top: 1px solid white;
}

.HomePage .HomePage-div .logos-img img {
  width: 60px;
  height: 60px;
  margin: 20px;
}

@media (max-width: 1500px) {
  .HomePage {
    width: 100%;
  }
  .HomePage .HomePage-div .headers {
    margin-bottom: 20px;
  }

  .HomePage .HomePage-div .headers h1,
  .HomePage .HomePage-div .headers h3 {
    font-size: 1.4rem;
  }

  .HomePage .HomePage-div .logos-img {
    padding-top: 10px;
  }

  .HomePage .HomePage-div .logos-img img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 800px) {
  .HomePage .HomePage-div {
    height: 80vh;
  }
}

@media (max-width: 550px) {
  .HomePage .HomePage-div .logos-img {
    flex-direction: column;
    border: none;
  }
  .HomePage .HomePage-div .logos-img img {
    margin: 10px;
  }
}
