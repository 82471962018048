body {
  margin: 0;
  max-width: 1500px;
  margin: 0 auto;
  background-color: rgb(248, 248, 248);
  color: white;
  font-family: 'Laila', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}
