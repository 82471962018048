.ContactMe {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContactMe div img {
  width: 50px;
  height: 50px;
}

.ContactMe .email-div {
  display: flex;
}

.ContactMe .email-div img {
  width: 30px;
  padding-right: 10px;
}

.ContactMe .github-linkedin {
  width: 200px;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 800px) {
  .ContactMe {
    height: 80vh;
  }
}
