.NavBar {
  position: fixed;
  bottom: 0;
  width: 70%;
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
  padding-top: 10px;
}

.NavBar button {
  background-color: transparent;
  color: white;
  width: 300px;
  border: none;
  outline: none;
  height: 40px;
  cursor: pointer;
  font-size: 1.1rem;
  margin: 0 10px;
}

.NavBar button:hover {
  color: rgb(157, 171, 255);
}

@media (max-width: 1500px) {
  .NavBar {
    width: 100%;
  }
  .NavBar button {
    font-size: 0.9rem;
  }
}
