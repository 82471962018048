.Projects {
  height: 100vh;
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Projects .projects-btns {
  display: none;
}

.Projects h2 {
  margin-bottom: 20px;
}

.Projects .project-temp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  margin-bottom: 60px;
}
.Projects .project-temp .cancel-btn {
  display: none;
}

.Projects .project-temp h4 {
  margin-bottom: 10px;
}

.Projects .project-temp div {
  display: flex;
}

.Projects .project-temp img {
  width: 200px;
}

.Projects .project-temp ul li {
  text-align: start;
}

.Projects .project-temp ul li a {
  text-decoration: none;
  color: rgb(253, 236, 152);
}

@media (max-width: 1500px) {
  .Projects {
    width: 94%;
  }
  .Projects h2 {
    margin-bottom: 10px;
  }
  .Projects .project-temp {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media (max-width: 800px) {
  .Projects h2 {
    display: none;
  }
  .Projects {
    justify-content: center;
    width: 98%;
    height: 80vh;
  }
  .Projects .projects-btns {
    display: block;
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
  }

  .Projects .projects-btns button {
    width: 300px;
    height: 50px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 1rem;
    background-color: rgb(252, 248, 243);
    font-weight: bold;
    color: black;
  }
  .Projects .project-temp {
    display: none;
  }
  .Projects .projects-btns button:hover {
    background-color: rgb(255, 255, 147);
  }
  #showBoo,
  #showMovie {
    display: flex;
    opacity: 0;
    animation: fadeIn forwards 4s;
    margin: 0;
    align-items: center;
  }

  #showMovie {
    height: 100vh;
    margin-top: 20px;
  }

  #showBoo {
    height: 80vh;
  }

  #showMovie h4,
  #showBoo h4 {
    font-size: 1.4rem;
    margin: 0;
  }

  #showMovie div,
  #showBoo div {
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    width: 100%;
  }

  #showMovie div ul li,
  #showBoo div ul li {
    font-size: 0.8rem;
  }

  #showBoo div img {
    width: 300px;
    height: 200px;
  }

  #showMovie div img {
    width: 200px;
    height: 280px;
  }

  #showMovie .cancel-btn,
  #showBoo .cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 36px;
    font-size: 0.8rem;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    outline: none;
    color: black;
  }
  #hideBtn {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
